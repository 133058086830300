@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
}

/* #root {
  display: none !important;
}
@media (min-width: 426px) and (max-width: 768px) {
  #root {
    display: none !important;
  }
  .comingSoon {
    height: 100vh;
    width: 100vw;
  }
}
ipad mini 
@media (min-width: 767px) and (max-width: 1025px) {
  #root {
    display: none !important;
  }
  .soon {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
  }
  .text-soon {
    font-size: 4rem;
    margin-bottom: 30px;
  }
  .comingSoon {
    height: 100vh;
    width: 100vw;
    display: block;
  }
  .comingSoon_container {
    flex-direction: column !important;
  }
  .img {
    width: 700px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1800px) {
  #root {
    display: none !important;
  }
  .comingSoon {
    height: 100vh;
    width: 100vw;
    display: block;
  }
  .text-soon {
    font-size: 60px;
    margin-bottom: 20px;
  }
} */

/* large lap  */
/* @media (min-width: 1025px) and (max-width: 1366px) {
  #root {
    display: none !important;
  }
  .comingSoon {
    height: 100vh;
    width: 100vw;
    display: block;
  }
} */
/* @media (min-width: 320px) and (max-width: 425px) {
  #root {
    display: block !important;
  }
  .comingSoon {
    display: none;
  }
} */
