.Simp_Himp {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;

  /* black */

  color: #070707;
}
.SqFt {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  margin-top: 6px;
  color: rgba(7, 7, 7, 0.5);
}
.Traditional {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(7, 7, 7, 0.5);
  margin-bottom: 0px;
}
.Traditional_sub {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: right;
  color: #e98700;
  margin-bottom: 0px;
}
.Pipe {
  border: 1px solid #d9d9d9;
}
.containerDesign {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: white;

  justify-content: center !important;
  align-items: center !important;
}
.containerDesignw {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: "rgba(34, 30, 31, 0.9)";
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.dotted_border {
  border: 1px dotted black;
}

input[type="radio"] {
  accent-color: #ac8a0f;
  height: 20px;
  width: 20px;
}
.uplode_file_btn {
  background: linear-gradient(116.31deg, #fcbd65 -25.27%, #e98700 126.96%);
  border-radius: 4px;
}
.Upload {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #717171;
}
.Uploadtype {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #717171;
}
.blur {
  background: rgba(243, 242, 239, 0.75);
  border-radius: 4px;
}

/* ribbon */
.ribbon {
  height: 188px;
  position: relative;
  margin-bottom: 30px;
  background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
  background-size: cover;
  text-transform: uppercase;
  color: white;
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.ribbon2 {
  width: 60px;
  padding: 10px 0;
  position: absolute;
  top: -6px;

  right: 13px;
  text-align: center;
  border-top-left-radius: 3px;
  background: #e98700;
}
.ribbon2:before {
  height: 0;
  width: 0;
  right: -5.5px;
  top: 0.1px;
  border-bottom: 6px solid #8d5a20;
  border-right: 6px solid transparent;
}
.ribbon2:before,
.ribbon2:after {
  content: "";
  position: absolute;
}
.ribbon2:after {
  height: 0;
  width: 0;
  bottom: -29.5px;
  left: 0;
  border-left: 30px solid #e98700;
  border-right: 30px solid #e98700;
  border-bottom: 30px solid transparent;
}

.PriceRange {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
}

/* range picker */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 37px 4px 55px 0px;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #e98700;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #717171;
  font-size: 12px;
  margin-top: 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f9e1bf;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #e98700;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: pink;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px blue;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.custom_hide {
  display: none;
}
input[type="radio"] {
  accent-color: #e98700;
  /* background-color: red !important; */
  height: 20px;
  width: 20px;

  /* outline: none !important;
  border: none !important; */
}

.bg_thankyou {
  background-color: rgba(34, 30, 31, 0.9);
}

/* web view rounded icon of otp form ---- */
.rounded-icon-web {
  /* color: blue !important; */
  padding-right: 5px;
  width: 2rem !important;
}
/* web view rounded icon end --------  */
/* .w-80 {
  width: 26rem !important;
} */
@media (min-width: 1024px) {
  .ribbon2 {
    right: 0px !important;
  }
  .rounded-icon-web {
    /* color: blue !important; */
    padding-right: 5px;
    width: 2rem !important;
  }
  /* start plaaning now form start -----  */
  .startPlanningWidth {
    width: 65% !important;
    margin-top: 10px;
  }
  .form_webview {
    display: flex !important;
    background-color: red !important;
  }
  /* start planning now end -------  */

  /* take our quiz start -------------  */
  .widthTakeOurQuiz {
    width: 40%;
    margin: auto !important;
    padding-top: 30px !important;
    padding: 30px 0;
    /* background-color: blue !important; */
  }
  /* take our quiz end --------  */

  /* method strong start from here------------ */
  .construction_head {
    font-size: 1.3rem !important;
    margin-bottom: 10px;
  }
  .construction_web {
    font-size: 1.2rem !important;
    line-height: 2rem;
  }
  .latest_cutting {
    font-size: 2.2rem;
  }
  /* methode strong end ---------  */

  /* quiz section start from here --------  */
  .Next {
    /* background-color: red; */
    /* border: 3px solid blue; */
    margin-left: 364px;
    margin-top: 10px;
  }
  /* quiz section end here -----  */

  /* footer section start from here ----------  */
  .Learn_More_sub {
    font-size: 16px !important;
  }
  /* footer section end --------  */
  .haveAquestion {
    font-size: 2.7rem;
    width: 30%;
    line-height: 50px;
    margin-top: 10px;
  }
  .frequentlyQuestion {
    font-size: 2.3rem;
    /* border: 1px solid red; */
  }
  .methodsWeb {
    width: 40%;
    font-size: 2rem;
  }
  .btnCloseRefresh {
    /* background-color: red !important; */
    position: absolute;
    top: 5%;
    left: 77%;
    cursor: pointer;
    /* border: 1px solid red; */
    /* display: flex;
    justify-content: end; */
    /* background-color: red; */
  }
}
