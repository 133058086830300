.navbar_container {
  background: #333333;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.16);
}
.Drawer_Navbar {
  position: absolute;
  background: rgba(25, 20, 20, 0.6);
  top: 0px;
  width: 100%;
  height: 60rem;
  border: 1px solid black;
  z-index: 9998;
}
.Drawer_container {
  position: absolute;
  background-color: #333333;
  width: 100%;
}
.QUIZ {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 425px) {
  /* navbar start -------------- */
  .sm_hidden {
    display: none !important;
  }

  /* .signUP:last-child {
    display: none !important;
  } */
  /* .signUp:last-child {
    display: none;
  }
  .signUP:last-child {
    display: none;
  } */

  /* navbar end -------------  */
}
@media (min-width: 1024px) {
  /* nav start ------------  */
  .web_hidden {
    display: none !important;
  }
  /* .visited {
    color: red !important;
  } */
  /* .visited:active {
    color: red !important;
  } */
  /* .signUp:active {
    color: #e98700 !important;
  }
  .signUp:visited {
    color: #e98700 !important;
  } */
  /* .visited:visited {
    color: red !important;
  }
  .visited {
    color: blue !important;
  } */
  /* .signUp:last-child {
    background: #e98700 !important;
    color: white !important;
    padding: 10px 14px;
    border-radius: 3px;
  } */
  /* nav end --------- */
}
/* .kVtMtA {
  display: none;
} */
