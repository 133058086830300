.Title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  /* orange */

  color: #070707;
}
.sub_Title {
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;

  color: rgba(7, 7, 7, 0.5);
}
.Start_Planningbtn {
  font-weight: 600 !important;

  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 70%;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12); */
  overflow: hidden;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.07));
}
.Trending_text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;

  /* black */

  color: #070707;
}
.Trending_container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}
.flag_position {
  position: absolute;
  right: 10px;
}
.flag1 {
  width: 52px;
  height: 70px;
  margin: 0 auto;
  padding-top: 8px;
  position: relative;

  background: #fcc201;
  color: white;
  text-align: center;
  font-family: msb;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.flag1:after {
  content: " ";
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 0px;
  height: 0;
  border-bottom: 1px solid #f8f8f8;
  border-left: 29px solid transparent;
  border-right: 23px solid transparent;
}
.Start_Now {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
  left: 18px;
  color: white;
  top: 17.5rem;
}
.Explore_StrongBuildBTN {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
  left: 5rem;
  color: white;
  top: 30.5rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.Professional_managementBTN {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
  left: 8rem;
  color: white;
  top: 14.5rem;
}
.Learn_More {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}
.Learn_More_sub {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}
.input-group {
  display: flex;
  align-items: center;
}
.copyRight {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #e98700;
  mix-blend-mode: normal;
}

.input {
  min-height: 30px;
  max-width: 150px;
  padding: 0 1rem;
  color: #fff;
  font-size: 15px;
  border: 1px solid #ffffff;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
  outline: none;
}

.button--submit {
  min-height: 30px;
  border: none;
  border-radius: 0 6px 6px 0;
  background: #efa540;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.inputField {
  width: 100%;
  /* background-color: transparent; */
  color: #717171;
  padding: 1.15rem 0.7rem;
  min-height: 40px;
  border-radius: 4px;
  outline: none;

  line-height: 1.15;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.07));
}

.inputField:hover {
  outline: 1px solid lightgrey;
}
.valuesOption {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #717171;
}
.Clearall {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration-line: underline;
  color: #717171;
  margin-bottom: 60px !important;
}
.did-floating-select {
  position: relative;
}

.did-floating-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0.5em 1.5em 0.5em 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}

.did-floating-select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0.5em;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
  transform: translateY(-50%);
}

.saveContinue {
  width: 50%;
  background: #e98700;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  height: 48px;
}
.Thankyou {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #494847;
}
.Thankyou_sub {
  font-family: "Play";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #717171;
}
.Form_container {
  background: #f3f2ef;
  height: 100vh !important;
  overflow-y: hidden !important;
}
.latest_cutting {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #494847;
}
.CONSTRUCTION {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #494847;
}
.CONSTRUCTION_sub {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #717171;
}
.title_form {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #717171;
  text-align: center;
}
.Thequiz {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #717171;
}
.Question {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #717171;
}
.back {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.Next {
  background: #333;
  border-radius: 4px;
  color: white;
}

/* search */
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
}

.inputtext {
  width: 100%;
  height: 48px;
  line-height: 28px;
  padding: 5px 1rem;
  padding-left: 2.5rem;
  border-radius: 6px;
  outline: none;
  background-color: #ffffff;
  color: #717171;
  transition: 0.3s ease;
  font-size: 14px;
}

.inputtext::placeholder {
  color: #717171;
  font-size: 14px;
  font-weight: 500;
}

.inputtext:focus,
input:hover {
  outline: none;
  background-color: #fff;
  /* box-shadow: 0 0 0 4px rgb(234 76 137 / 10%); */
}

.icon {
  position: absolute;
  left: 1rem;
  top: 28%;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}
.to {
  margin-top: 9px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
}
/* sharan styles here  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.bg_issue {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.lableFor {
  margin-top: 15px;
  color: #717171;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
}
.selectFeild {
  background: white;
}

.ran {
  position: absolute;
  top: 45%;
}

.did-floating-label-content {
  position: relative;
}
.did-floating-select {
  /* Existing styles */

  /* Add relative positioning */
  position: relative;
}

.did-floating-label {
  color: #717171;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 11px;
  top: 13px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
  background-color: transparent;
  color: #717171;
  padding: 1.15rem 0.7rem;
  border-radius: 4px;
  outline: none;
  line-height: 1.15;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.07));
  font-size: 14px;
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
}
.did-floating-select::after {
  /* Position the arrow at the end */
  content: "";
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to position the arrow as desired */
  transform: translateY(-50%);
  pointer-events: none;
  width: 10px; /* Adjust the width and height to style the arrow */
  height: 10px;
  background-color: #717171; /* Set the color of the arrow */
  /* Add arrow styles like background-image, border, etc., if needed */
}

.did-floating-select {
  border: 1px solid rgba(132, 123, 123, 0.223);
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.07));
}
.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.07));
}
.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}

/* rahul  */
.Title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  /* orange */

  color: #070707;
}
.sub_Title {
  font-family: "Lato";
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;

  color: rgba(7, 7, 7, 0.5);
}
.Start_Planningbtn {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 70%;
}
.Trending_text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;

  /* black */

  color: #070707;
}
.Trending_container {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}
.flag_position {
  position: absolute;
  right: 10px;
}
.flag1 {
  width: 52px;
  height: 70px;
  margin: 0 auto;
  padding-top: 8px;
  position: relative;

  background: #fcc201;
  color: white;
  text-align: center;
  font-family: msb;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.flag1:after {
  content: " ";
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 0px;
  height: 0;
  border-bottom: 1px solid #f8f8f8;
  border-left: 29px solid transparent;
  border-right: 23px solid transparent;
}
.Start_Now {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
  left: 48px;
  color: white;
  top: 17.5rem;
}
.Explore_StrongBuildBTN {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
  left: 5rem;
  color: white;
  top: 30.5rem;
}

.Professional_managementBTN {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: relative;
  left: 8rem;
  color: white;
  top: 14.5rem;
}
.Learn_More {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}
.Learn_More_sub {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}
.input-group {
  display: flex;
  align-items: center;
}
.copyRight {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #e98700;
  mix-blend-mode: normal;
}

.input {
  min-height: 30px;
  max-width: 150px;
  padding: 0 1rem;
  color: #fff;
  font-size: 15px;
  border: 1px solid #ffffff;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
  outline: none;
}
.titleCardBlack {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
}
.tagtitleSubblack {
  font-family: "Play";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #e6e6e6;
}
.selectingtext {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.soon {
  font-family: "Play";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #c0c0c0;
}
.Resend {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #e98700;
}
.submit {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
.submitNExt {
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.button--submit {
  min-height: 30px;
  border: none;
  border-radius: 0 6px 6px 0;
  background: #efa540;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.inputField {
  width: 100%;
  /* background-color: transparent; */
  color: #717171;
  padding: 1.15rem 0.7rem;
  min-height: 40px;
  border-radius: 4px;
  outline: none;

  line-height: 1.15;
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.07));
}

.inputField:hover {
  outline: 1px solid lightgrey;
}
.valuesOption {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #717171;
}
.Clearall {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration-line: underline;
  color: #717171;
  margin-bottom: 60px !important;
}
.saveContinue {
  width: 50%;
  background: #e98700;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
.Thankyou {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #494847;
}

.Form_container {
  background: #f3f2ef;
  height: 100vh !important;
  overflow-y: hidden !important;
}
.latest_cutting {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #494847;
}
.CONSTRUCTION {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #494847;
}
.CONSTRUCTION_sub {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #717171;
}
.title_form {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #717171;
  text-align: center;
}
.Thequiz {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #717171;
}
.Question {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
}
.back {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.Next {
  background: #333;
  border-radius: 4px;
  color: white;
  margin-left: 158px;
}

/* search */
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
}

.inputtext {
  width: 100%;
  height: 58px;
  line-height: 28px;
  padding: 20px 1rem;
  padding-left: 2.5rem;
  font-weight: 300 !important;
  border-radius: 6px;
  outline: none;
  background-color: #ffffff;
  color: #0d0c22;
  transition: 0.3s ease;
}

.inputtext::placeholder {
  color: #9e9ea7;
}

.inputtext:focus,
input:hover {
  outline: none;
  background-color: #fff;
  /* box-shadow: 0 0 0 4px rgb(234 76 137 / 10%); */
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}
.to {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
}
/* sharan styles here  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.bg_issue {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.lableFor {
  margin-top: 15px;
  color: #717171;
  font-weight: 500;
  font-size: 14px;
}
.selectFeild {
  background: white;
}

/* ---- rahul ----  */

/* faq page style -------  */
#bg_Gray {
  background: #f3f2ef;
  font-family: "Lato" !important ;
  box-shadow: none !important;
  border-radius: none !important;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  margin-top: 8px;
  margin-bottom: 10px;
}

@media (min-width: 320px) and (max-width: 425px) {
  /* navbar -------  */
  .rounded_circle {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
  }
  .MuiSvgIcon-fontSizeMedium {
    width: 26px !important;
  }
  .ribbon2 {
    right: 0px !important ;
  }
  /* navbar end -----------  */
  /* trending card -----------  */
  .heart_radius {
    /* border: 2px solid pink !important; */
    background-color: #f3f2efbf !important;
    border-radius: 4px !important;
    padding: 2px !important;
  }
  /* trending card end ------  */
  /* already have a design start--  */
  .Start_Now {
    left: 27px !important;
  }
  /* hero section start here 27_05_2023 ---------  */
  .small_hidden {
    display: none;
  }

  /* hero section end ----------  */

  .fifty_thousand {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .jVJmF {
    grid-template-columns: repeat(0, 100%) !important;
    grid-template-rows: 1fr;
  }
}
/* .homebg {
  background-image: url(../Assets/traditonalHomes/Homephoto.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-position: center;
  background-size: cover; 
  height: 400px;
  z-index: 3333333;
} 
*/
/* 
@media only screen and (min-width: 1024px) {
  #bghidden {
    background-image: none;
  }
  .Start_Now {
    position: static;
    left: 0;
  }
  .Explore_StrongBuildBTN {
    position: static;
    left: 0;
  }
  .oneone {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 20%;
    left: 5%;
    z-index: 200;
  }
  .Title {
    background-color: red;
    font-size: 40px;
    line-height: 1.2;
    color: #2d3f3d;
  }
  .sub_Title {
    margin-top: 15px;
  }
} */
/* rahul end ------------- */

@media (min-width: 1024px) {
  /* hero section start from here---------  */
  .home_content {
    /* border: 1px solid red; */
    position: absolute;
    top: 20%;
    left: 1%;
  }
  .Title {
    font-size: 50px;
    line-height: 50px;
    color: #2d3f3d;
  }
  .sub_Title {
    font-size: 24px;
  }
  .Start_Planningbtn {
    width: 300px;
    margin-top: 14px;
    font-family: "Montserrat" !important;
    font-weight: 500;
  }
  /* hero section end --------  */

  /* trending ready to build design start from here ------  */
  .Trending_text {
    font-size: 36px;
    font-weight: 500;
    /* margin-bottom: 10px; */
  }
  .trending_ready {
    /* border: 1px solid red; */
    margin: 0 40px;
  }
  .heart_radius {
    /* border: 2px solid pink !important; */
    background-color: #f3f2efbf !important;
    border-radius: 4px !important;
    padding: 2px !important;
  }
  /* Trending ready to build design end ----  */

  /* already have a design start -------  */
  .we_are {
    line-height: 43px;
  }
  /* already have a design end ------------  */

  /* strong build homes carousel section start ----  */
  .strong_build_homes {
    width: 100% !important;
    /* border: 3px solid red; */
    padding: 0 30px;
    margin-bottom: 1.3rem;
    /* display: block !important;
    display: flex !important; */
  }
  /* strong build homes section end -------  */

  /* proffesional manaagement start from here ------- */
  .proffesional_management {
    /* background-color: red; */
    font-weight: 700;
    margin: 30px 0;
  }
  /* * proffesional manaagement start from here end----- */

  /* faq section started ---------  */
  .faq_number {
    font-size: 26px !important;
    font-weight: 500 !important;
    color: #494847 !important;
  }

  /* faq section end -----  */

  /* quiz card start from here ---------  */
  .Question {
    font-size: 1em;
  }

  /* quiz card end here ----  */
  /* 
  .alreadyHaveaDesign {
    border: 4px solid blue !important;
  }
  .imgProblem {
    border: 2px solid pink;
    margin-right: -50px;
  } */
}
